import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();

let burger = document.getElementById('burger'),
    nav = document.getElementById('main-nav-mobil');

burger.addEventListener('click', function (e) {
    this.classList.toggle('is-open');
    nav.classList.toggle('is-open');
});

nav.addEventListener('click', function (e) {
    burger.classList.toggle('is-open');
    nav.classList.toggle('is-open');
})

$(document).ready(function () {

    // Contact Form send
    if ($('#contact').length) {
        $('#contact').on("formvalid.zf.abide", function (ev, frm) {
            $.ajax({
                type: "POST",
                url: 'contact.php',
                dataType: 'json',
                cache: false,
                data: $('form').serialize(),
                success: function (data) {

                    if (data.info === 'error') {
                        console.log(data.msg);
                    }
                    if (data.info === 'success') {
                        console.log(data.msg);
                    } else {
                        console.log(data.msg);
                    }
                }
            });
            $('.success.callout').parent().show();
            $('#contact')[0].reset();

        }).on("submit", function (ev) {
            ev.preventDefault();
            console.log("Submit for form id " + ev.target.id + " intercepted");
        });
    }
});
